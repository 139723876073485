import "./app.css"
import { ReactComponent as Logo } from "./assets/images/logo-wrapper.svg"
import Tool from "./assets/images/tool.png"

export default function App() {
  return (
    <div className="koders">
      <div className="logo">
        <Logo />
        {/* <img src={Icon} alt="koders-icon" className="koders-icon" /> */}
        {/* <img src={Tool} alt="koders-tool" className="koders-tool" /> */}
      </div>
      <h2>This page is under Maintenance.</h2>
      <p>We'll be here as soon as possible...</p>
    </div>
  )
}
